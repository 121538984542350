<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card
          :loading="loading"
          :disabled="loading"
          loader-height="2"
          class=""
        >
          <v-card-text>
            <div class="d-flex align-center mb-4">
              <v-btn
                small
                icon
                @click="$nova.gotoLink({ path: '/ia/project' })"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>

              <v-btn icon small @click="refreshData()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>

              <v-chip
                color="info"
                v-if="metadataList.stage === 0"
                small
                label
                class="body-2 ml-2"
              >
                Open
              </v-chip>
              <v-chip
                color="warning"
                small
                v-if="metadataList.stage === 1"
                label
                class="body-2 ml-2"
              >
                Review
              </v-chip>
              <v-chip
                color="success"
                small
                v-if="metadataList.stage === 2"
                label
                class="body-2 ml-2"
              >
                Close
              </v-chip>
              <br />
            </div>

            <div class="mb-1 text-center">
              <div class="d-flex align-center">
                <div class="subtitle-1 font-weight-bold mr-2">
                  {{ metadataList.name }} -
                  <span class="mr-1">{{
                    $nova.formatDate(metadataList.from)
                  }}</span>
                  <span class="font-weight-bold">to</span>
                  <span class="mr-1 ml-1">{{
                    $nova.formatDate(metadataList.to)
                  }}</span>
                </div>
                <v-spacer></v-spacer>
                <v-chip
                  color="info"
                  small
                  class="mr-1"
                  v-if="metadataList.stage === 0 && !isreviewer"
                  @click="sendReview()"
                  >Send Review</v-chip
                >
                <v-chip
                  color="info"
                  small
                  class="mr-1"
                  v-if="metadataList.stage === 1 && isreviewer"
                  @click="sendPreparer()"
                  >Send Preparer</v-chip
                >
                <v-chip
                  color="error"
                  small
                  class="mr-1"
                  v-if="metadataList.stage === 1 && isreviewer"
                  @click="sendClose()"
                  >Close</v-chip
                >
                <v-chip
                  color="info"
                  small
                  class=""
                  v-if="metadataList.stage === 2 && isreviewer"
                  @click="sendPreparer()"
                  >Re-open</v-chip
                >
                <div v-else-if="isreviewer" class="warning--text">
                  Waiting for preparer to finish the analysis
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <div class="d-flex align-center mb-2 primary--text mr-4">
                <span class="display-1 font-weight-bold">{{
                  ((setupList || {}).verification || []).length
                }}</span>
                <span class="body-2 ml-1 line-1">Total<br />Count</span>
              </div>
              <div class="d-flex align-center mb-2 info--text mx-4">
                <span class="display-1 font-weight-bold">
                  {{ checkbox.length || [] }}</span
                >
                <span class="body-2 ml-1 line-1">Select<br />Count</span>
              </div>

              <div class="d-flex align-center mb-2 success--text mx-4">
                <span class="display-1 font-weight-bold">0</span>
                <span class="body-2 ml-1 line-1">Finsh<br />Count</span>
              </div>
              <div class="d-flex align-center mb-2 warning--text mx-4">
                <span class="display-1 font-weight-bold">0</span>
                <span class="body-2 ml-1 line-1">Pannding<br />Count</span>
              </div>
              <div class="d-flex align-center mb-2 error--text mx-4">
                <span class="display-1 font-weight-bold">0</span>
                <span class="body-2 ml-1 line-1">Error<br />Count</span>
              </div>
            </div>
          </v-card-text>
          <v-card-text class="pb-0">
            <v-tabs v-model="tab" height="40">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab key="0" class="text-transform-none justify-start">
                <v-icon class="mr-2">mdi-server-security</v-icon>
                Verifications
              </v-tab>

              <v-tab
                key="1"
                class="text-transform-none justify-start"
                v-if="parameterList.length > 0"
              >
                <v-icon class="mr-2">mdi-play-box-multiple-outline </v-icon>
                Input Parameters
              </v-tab>
              <v-tab
                key="2"
                class="text-transform-none justify-start"
                v-if="fileList.length > 0"
              >
                <v-icon class="mr-2">mdi-cloud-upload-outline </v-icon>
                File
              </v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-btn
                v-if="tab === 0"
                small
                class="mt-3"
                color="primary"
                @click="nextStpeOne()"
                >Save</v-btn
              >
              <v-btn
                v-if="tab === 1"
                small
                class="mt-3"
                color="primary"
                @click="nextStpeTwo()"
                >Save</v-btn
              >
            </div>
            <v-tabs-items v-model="tab" class="">
              <v-tab-item key="0">
                <v-card class="shadow-off">
                  <v-card-text class="">
                    <!-- {{setupList.verification}} -->
                    <v-expansion-panels class="shadow-off">
                      <v-expansion-panel
                        v-for="(area, i) in areaList"
                        :key="i"
                        class="shadow-off"
                      >
                        <v-expansion-panel-header class="shadow-off">
                          {{ area.name }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-expansion-panels>
                            <v-expansion-panel
                              v-for="(subarea, s) in subareaList"
                              :key="s"
                            >
                              <v-expansion-panel-header
                                v-if="area._id === subarea.group"
                              >
                                {{ subarea.name }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <div
                                  v-for="(verify, k) in VerificationList"
                                  :key="k"
                                >
                                
                                  <v-checkbox
                                    v-if="subarea._id === verify.area[0]"
                                    :label="verify.name"
                                    :value="verify._id"
                                    v-model="checkbox"
                                    dense
                                    hide-details
                                  >
                                  </v-checkbox>
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="1" v-if="parameterList.length > 0">
                <v-card class="shadow-off">
                  <v-card-text class="px-0">
                    <template>
                      <div v-for="(c, d) in parameterList" :key="'B' + d">
                        <div class="d-flex ma-3 mt-2">
                          <span class="font-weight-black mr-2"
                            >{{ d + 1 }}.</span
                          >{{ c.name }}
                          <v-spacer></v-spacer>
                          <div style="width: 250px">
                            <div
                              v-if="c.type === 'number'"
                              class="parameter-input"
                            >
                              <lb-number
                                v-model="inputParamData[c._id]"
                                :hidedetails="true"
                                :disabled="isreviewer"
                              />
                            </div>
                            <div
                              v-else-if="c.type === 'string'"
                              class="parameter-input"
                            >
                              <lb-string
                                v-model="inputParamData[c._id]"
                                :hidedetails="true"
                                :disabled="isreviewer"
                              />
                            </div>
                            <div
                              v-else-if="c.type === 'date'"
                              class="parameter-input"
                            >
                              <lb-date
                                v-model="inputParamData[c._id]"
                                :hidedetails="true"
                                :disabled="isreviewer"
                              />
                            </div>
                            <div
                              v-else-if="c.type === 'year'"
                              class="parameter-input"
                            >
                              <lb-year
                                v-model="inputParamData[c._id]"
                                :hidedetails="true"
                                :disabled="isreviewer"
                              />
                            </div>
                            <div
                              v-else-if="c.type === 'month'"
                              class="parameter-input"
                            >
                              <lb-month
                                v-model="inputParamData[c._id]"
                                :hidedetails="true"
                                :disabled="isreviewer"
                              />
                            </div>
                            <div
                              v-else-if="c.type === 'time'"
                              class="parameter-input"
                            >
                              <lb-time
                                v-model="inputParamData[c._id]"
                                :hidedetails="true"
                                :disabled="isreviewer"
                              />
                            </div>
                            <div
                              v-else-if="c.type === 'daterange'"
                              class="parameter-input"
                            >
                              <lb-daterange
                                v-model="inputParamData[c._id]"
                                :hidedetails="true"
                                :disabled="isreviewer"
                              />
                            </div>
                            <div
                              v-else-if="c.type === 'timerange'"
                              class="parameter-input"
                            >
                              <lb-timerange
                                v-model="inputParamData[c._id]"
                                :hidedetails="true"
                                :disabled="isreviewer"
                              />
                            </div>
                            <div
                              v-else-if="
                                [
                                  'numberarray',
                                  'stringarray',
                                  'datearray',
                                  'yeararray',
                                  'montharray',
                                  'timearray',
                                  'daterangearray',
                                  'timerangearray',
                                ].indexOf(c.type) > -1
                              "
                              class="parameter-input"
                            >
                              <lb-list
                                :allowrepeat="true"
                                :type="c.type"
                                v-model="inputParamData[c._id]"
                                :hidedetails="true"
                                :disabled="isreviewer"
                              />
                            </div>
                          </div>
                        </div>
                        <v-divider></v-divider>
                      </div>
                    </template>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="2" v-if="fileList.length > 0">
                <v-card class="shadow-off">
                  <v-card-text class="px-0">
                    <template class="">
                      <div v-for="(a, b) in fileList" :key="'a' + b">
                        <div class="subtitle-2 align-center d-flex">
                          <span class="font-weight-black mr-2"
                            >{{ b + 1 }}.{{ a.name }}</span
                          >
                          <v-icon
                            color="success"
                            class="ml-2"
                            small
                            v-if="fileUploadedDetilas[a._id]"
                            >mdi-check-circle</v-icon
                          >
                          <v-icon color="info" class="ml-2" small v-else
                            >mdi-timer-sand</v-icon
                          >
                          <v-spacer></v-spacer>
                          <span v-if="fileprocesserrors[a._id]" class="ml-2">
                            <span class="error--text">{{
                              fileprocesserrors[a._id] || "Unknown Error"
                            }}</span>
                          </span>
                          <span
                            v-if="(fileUploadedDetilas[a._id] || {}).moved"
                            @click="
                              $nova.downloadFile(
                                (fileUploadedDetilas[a._id] || {})._id
                              )
                            "
                            class="mx-2 cursor-pointer blue--text text--darken-2 caption"
                          >
                            <v-icon class="mr-1 blue--text text--darken-2"
                              >mdi-file</v-icon
                            >
                            {{ fileUploadedDetilas[a._id].uploadname }}
                          </span>

                          <span
                            v-if="0"
                            @click="$nova.downloadFile()"
                            class="mx-2 cursor-pointer blue--text text--darken-2 caption"
                          >
                            <v-icon class="mr-1 blue--text text--darken-2"
                              >mdi-file</v-icon
                            >
                            file_name.csv
                          </span>

                          <div
                            class="ml-2 d-flex align-center"
                            v-if="!isreviewer"
                          >
                            <lb-file
                              v-model="fileUploadData[a._id]"
                              :hidedetails="true"
                              :drag="false"
                              accept=".csv,.xlsx,.xls"
                              label=""
                              :displayname="false"
                              @change="FileProcess(a._id, a.columns)"
                            />
                          </div>
                        </div>
                        <div>Requirement columns:</div>
                        <div
                          v-if="
                            setupList.fileconfig[a._id] &&
                            setupList.fileconfig[a._id].columnmap
                          "
                        >
                          <v-chip
                            class="mr-2 mb-1"
                            v-for="(v, k) in setupList.fileconfig[a._id] &&
                            setupList.fileconfig[a._id].columnmap"
                            :key="k"
                            x-small
                            >{{ v[1] }}</v-chip
                          >
                        </div>

                        <div v-else>
                          <v-chip
                            class="mr-2 mb-1"
                            v-for="(v, k) in a.columns"
                            :key="k"
                            x-small
                            >{{ v.name }}</v-chip
                          >
                        </div>
                        <v-divider></v-divider>
                      </div>
                    </template>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </div>
    </div>
    {{ dataTable }}
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      tab: null,
      isreviewer: false,
      totalList: [],
      loading: false,
      checkbox: [],
      allList: [],
      parameterList: [],
      fileList: [],
      fileSetupList: [],
      inputParamData: {},
      fileUploadData: {},
      projectid: null,
      areaList: [],
      subAreaList: [],
      VerificationList: [],
      projectDetials: {},
      metadataList: {},
      setupList: {},
      dataTable: [],
      fileprocessstage: {},
      fileprocesserrors: {},
      fileuploadpercent: {},
      counter: 0,
      fileConfigInfo: {},
      fileStatusInfo: {},
      fileUpDetil: {},
      fileUploadedDetilas: {},
    };
  },
  created() {
    this.projectId = this.$route.params.projectid;
    this.refreshData();
  },
  computed: {},
  methods: {
    refreshData() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/iaprojects/getmetadata/" + this.projectId)
        .then((dt) => {
          if (dt.data.status === "success") {
            let dataLists = dt.data.data[0];
            console.log(dataLists, "dataLists");

            /* Assign Mata Data And Setup Data */
            this.metadataList = dataLists.metadata;
            this.setupList = dataLists.setup;

            /* Verification Setup */
            this.areaList = this.setupList.area;
            this.subareaList = this.setupList.subarea;
            this.VerificationList = this.setupList.verification;

            this.checkbox = this.metadataList.verification;
            this.fileConfigInfo = this.setupList.fileconfig || {};

            console.log(this.setupList.parameters);

            /* Parameters Setup */
            let parameterIDs = [];
            for (let j = 0; j < this.checkbox.length; j++) {
              for (let k = 0; k < this.VerificationList.length; k++) {
                if (this.checkbox[j] === this.VerificationList[k]._id) {
                  parameterIDs = parameterIDs.concat(
                    this.VerificationList[k].parameters
                  );
                }
              }
            }
            this.parameterList = [];
            let selectParam = [...new Set(parameterIDs)];

              for (const k of this.setupList.parameters) {
                if (selectParam.indexOf(k._id) > -1) {
                this.parameterList.push(k);
              }
            }
            this.inputParamData = this.metadataList.inputparameter;

            /* File Details */

            this.fileUploadedDetilas = dataLists.uploaddetails;
            console.log(this.fileUploadedDetilas, "fileUploadedDetilas");

            /* File Setup Setup */

            let filesIDs = [];
            for (let j = 0; j < this.metadataList.verification.length; j++) {
              for (let k = 0; k < this.VerificationList.length; k++) {
                if (
                  this.metadataList.verification[j] ===
                  this.VerificationList[k]._id
                ) {
                  filesIDs = filesIDs.concat(this.VerificationList[k].files);
                }
              }
            }
            console.log(filesIDs);
            let setupFileList = this.setupList.files;
            
            const selecFiles = setupFileList.filter((x) => {
              return filesIDs.some((y) => {
                return y.id === x._id;
              });
            });
            this.fileList = selecFiles;
          }
        })
        .catch((err) => {
          console.log(err, "DD");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    nextStpeOne() {
      console.log(this.checkbox);
      this.axios
        .post("/v2/conserve/iaprojects/seoreverifications/" + this.projectId, {
          data: { verification: this.checkbox },
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.tab = 1;
            this.refreshData();
          }
        })
        .catch((dt) => {
          console.log(dt);
        });
    },
    nextStpeTwo() {
      let objectInput = { inputparameter: this.inputParamData };
      this.loading = true;
      this.axios
        .post("/v2/conserve/iaprojects/storeinputparameter/" + this.projectId, {
          data: objectInput,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.tab = 2;
            this.refreshData();
          } else throw new Error("Error starting Review");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sendReview() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/iaprojects/review/" + this.projectId)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
          } else throw new Error("Error starting Review");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendPreparer() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/iaprojects/open/" + this.projectId)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
          } else throw new Error("Error starting Review");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendClose() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/iaprojects/close/" + this.projectId)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
          } else throw new Error("Error starting Review");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    FileProcess(id, columns) {
      if (this.fileUploadData[id] && this.fileprocessstage[id] !== true) {
        this.fileprocessstage[id] = true;
        this.fileprocesserrors[id] = "";
        let file = this.fileUploadData[id];
        let config = this.fileConfigInfo[id];
        let columArr = [];
        for (const i of columns) {
           columArr.push([i.name, i.name]);
        }
        this.$nova.fileReading(file, config, columArr,this.axios)
          .then((dt) => {
            if (dt.data.status === "success") {
              this.fileUpDetil = dt.data.data[0];
              
              return this.axios.post("/v2/conserve/iaprojects/storefile/" + this.projectId, {
                  data: { upload: this.fileUpDetil._id, file: id },
                })
            } else throw new Error("Unable to upload file")
          })
          .then((dt) => {
              if (dt.data.status === "success") {
                this.fileUploadedDetilas = dt.data.data;
                this.refreshData();
              } else throw new Error("Not Stored Project Detilas");
            })
          .catch((err) => {
            this.fileUploadData[id] = null;
            this.fileprocesserrors[id] = err.message || err || "Unknown error!";
            console.log(err);
          })
          .finally(() => {
            this.fileprocessstage[id] = false;
          });
      }
    },
  },
};
</script>
  